
@font-face {
  font-family: AvenirNext;
  src: url('./fonts/AvenirNextLTPro-Regular.otf');
}

@font-face {
  font-family: AvenirNextBold;
  src: url('./fonts/AvenirNextLTPro-Bold.otf');
}


/* div.container button.button, div.container button.is-primary, div.container button.is-primary:hover {
  background-color: #21b3cf;
} */
