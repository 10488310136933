
@include until($navbar-breakpoint) {
    .columns-search-buttons {
        margin: 0;
        justify-content: space-evenly;
        .column {
            padding: 0; } } }

@include from($navbar-breakpoint) {

    body.advancedsearch {
        background-image: url('../images/record-player-3.png'), url('../images/horn-blue-2.png'), url('../images/horn-red-2.png'), url('../images/record-blue.png');
        //

        //background-position: bottom 200px left 279px, top 375px left -50px, top 400px right -600px, bottom 250px right 265px

        background-position: bottom 194px left calc(50% - 460px), top 765px left calc(50% - 745px), top 447px right calc(50% - 905px), bottom 230px right calc(50% - 490px);
        //, , top 306px right calc(50% - 643px);

        footer.footer {
            margin-top: 465px; } }

    body.advancedsearchresults {
        background-image: url('../images/record-player-6.png'), url('../images/horn-blue-2.png'), url('../images/horn-red-2.png');
        background-position: bottom 200px left calc(50% - 482px), top 375px left calc(50% - 745px), top 650px right calc(50% - 1085px);
        footer.footer {
            margin-top: 405px; } } }


.or-mobile {
    @extend .is-hidden-desktop;
    padding: 5px; }

.or-desktop {
    @extend .is-narrow;
    @extend .is-hidden-touch;
    width: 35px; }
