
@include until($navbar-breakpoint) {
    .block {
        font-size: 0.85em;
        line-height: 1.3; }

    .subtitle:not(:last-child) {
        margin-bottom: 2rem; }

    button.button.advanced-search {
        width: 100%; } }


@include from($navbar-breakpoint) {

    body.home {
        background-repeat: no-repeat;
        background-image: url('./images/horn-green-3.png'), url('./images/record.png'), url('./images/record-player-2.png'), url('./images/horn-blue.png');
        background-position: bottom 0 left calc(50% - 810px), top 375px left calc(50% - 720px), bottom 275px right calc(50% - 280px), top 306px right calc(50% - 643px); }

    footer.footer {
        margin-top: 500px; }

    form {
        margin-bottom: 2rem; }

    .advanced-search-column {
        padding: 0; } }



button.button.advanced-search {
    color: $secondary;
    font-weight: bolder;
    text-underline-offset: 3px; }
