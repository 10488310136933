@include until($navbar-breakpoint) {
    .block {
        font-size: 0.95em; } }

@include from($navbar-breakpoint) {

    body.about {
        background-image: url('./images/record-player-4.png'), url('./images/horn-gold-2.png'), url('./images/horn-blue.png'), url('./images/layer-576.png');
        background-position: bottom 126px left calc(50% - 440px), top 593px left calc(50% - 750px), bottom 55px right calc(50% - 250px), top 540px right calc(50% - 880px);
        footer.footer {
            margin-top: 300px; }
        p {
            max-width: 850px; } } }
