
table.results {
    font-size: 0.8em;
    width: 100%; }

table.results thead tr td.sortable {
    cursor: pointer; }

table.results tbody tr {
    cursor: pointer; }

table.results tbody tr.detail {
    cursor: default;
    background-color: $grey-light; }

table.results tr.selected {
    background-color: #90d9e7;
    td {
        border-width: 0; } }

.table tbody tr:last-child td {
    border-width: $table-cell-border-width; }

.pagination {
    font-size: 0.8rem;
    font-weight: bold; }

.pagination button {
    margin-right: 0.5rem;
    img {
        width: 26px;
        max-width: 26px; } }


.button.is-secondary {
    min-width: 100px; }
