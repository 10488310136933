@charset "utf-8";


@import '../node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../node_modules/bulma/sass/utilities/functions.sass';

//$desktop: 844px + (2 * $gap)

$primary: #2d2e83;

$secondary: #63cadd;

$grey-dark: #3d3d3d;
$grey-light: #f3f3f3;

$input-placeholder-color: #2c2c2c;
$input-border-color: #acacac;
$input-hover-border-color: darken($input-border-color, 20%);

$link: #21b3cf;
$link-invert: $black;

$title-color: $primary;
$title-weight: $weight-bold;
$family-sans-serif: 'AvenirNext', sans-serif;

$table-head-cell-border-width: 0;
$border: $grey-dark; //table


@import '../node_modules/bulma/sass/utilities/derived-variables.sass';

//$secondary-invert: findColorInvert($secondary);
$secondary-invert: $black;
$custom-colors: ("secondary": ($secondary, $secondary-invert));
$colors: map-merge($colors, $custom-colors);


$modal-content-width: 980px;

// Import Bulma
@import '../node_modules/bulma/bulma.sass';

p {
    letter-spacing: -0.32px; }

.title {
    font-size: 2.25rem;
    font-family: 'AvenirNext';
    font-weight: 1000;
    letter-spacing: -0.8px; }


.subtitle {
    font-size: 1.5rem;
    font-family: 'AvenirNext';
    font-weight: 1000;
    letter-spacing: -0.8px;
    &:not(:last-child) {
        margin-bottom: 0.5rem; } }

.logo {
    margin-bottom: $size-3*2;
    width: 350px; }

ol {
    margin-left: 1em;
    left:  200px; }

ol li {
    margin-bottom: 0.0em; }

ul li {
    margin-top: 0em;
    margin-bottom: 0em; }


ul.dots li {
    list-style-type: disc;
    margin-left: 1em; }

table thead tr {
    font-weight: bolder; }

.navbar {
    @include from($navbar-breakpoint) {
        .nav-container {
            align-items: stretch;
            display: flex;
            min-height: 3.25rem;
            width: 100%; }

        .nav-container {
            flex-grow: 1;
            margin: 0 auto;
            position: relative;
            width: auto;
            &.is-fluid {
                max-width: none !important;
                padding-left: $gap;
                padding-right: $gap;
                width: 100%; }
            @include desktop {
                max-width: $desktop - $container-offset; } }


        .nav-container {
            min-height: 4.0rem; }

        .navbar-start > a.navbar-item {
            font-size: 0.8em; }

        .navbar-start > a.navbar-item.is-active {
            background-repeat: no-repeat;
            background-image: url('./images/nav-on-state.png');
            background-position:  bottom 10px center;
            background-color: transparent; } } }

@include until($navbar-breakpoint) {
    .navbar.is-primary {
        height: 0px;
        min-height: 0px;
        background-color: transparent;
        .navbar-burger.is-large {
            color: $black;
            transform: scale(2) translateX(-20%) translateY(20%); }
        .navbar-burger:hover {
            background-color: transparent; } }

    .navbar-item-logo {
        padding: 20px;
        padding-top: 0;
        margin: 0; }


    .navbar-menu {
        box-shadow: 0px 0px 0px 0px; }

    .navbar-item {
        text-align: left;
        padding: 20px;

        border-bottom: solid 2px $primary;
        font-size: 1.2em;
        font-weight: bolder;
        margin-left: 25px;
        margin-right: 25px;
        padding-left: 0px; }

    #main-section {
        margin-top: 130px; }

    a img.logo {
        width: 265px;
        margin-top: -2em;
        margin-bottom: 1em; }

    #main-section {
        padding-top: 25px; }
    #main-section.is-active {
        display: none; }
    .footer.is-active {
        display: none; }

    .button.is-secondary {
        height: 3.5em; }

    .input {
        height: 3.5em; } }


.fields {
    margin-top: $size-1; }

.button.is-secondary {
    font-weight: bold;
    min-width: 160px;
    border-radius: 8px; }

@include until($navbar-breakpoint) {
    .button.is-secondary {
        min-width: 100%; } }

#main-section {
    min-height: calc(100vh - 201px); }

body {
  margin: 0;
  font-family: AvenirNext, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }


body {
  font-family: 'AvenirNext'; }


footer.footer {
  background-color: black;
  color: $white;
  font-size: 0.8rem;
  padding: 2rem; }


footer.footer p {
  margin-bottom: 1rem; }


footer.footer p:last-of-type {
  margin-bottom: 0; }

@include from($navbar-breakpoint) {
    body {
        background-repeat: no-repeat; } }


@include from(1750px) {
  footer.footer {
    margin-top: 500px; } }





@include from($navbar-breakpoint) {
    body.advancedsearch {
        .fields {
            max-width: 750px; } } }

body.feedback {
    .fields {
        max-width: 750px; }
    .subtitle {
        max-width: 750px; } }


.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    &:after {
        @include loader;
        position: absolute;
        top: calc(50% - 5em);
        left: calc(50% - 5em);
        width: 10em;
        height: 10em;
        border-width: 0.5em; } }

.has-loading {
    position: relative;
    pointer-events: none;
    opacity: 1.0;
    &:after {
        @include loader;
        position: absolute;
        top: calc(50% - 5em);
        left: calc(50% - 5em);
        width: 10em;
        height: 10em;
        border: 12px solid $primary;
        border-right-color: transparent;
        border-top-color: transparent; } }


.message.is-link {
    .message-body {
        color: $grey-dark;
        ul {
            list-style: none; } } }

@import './Home.sass';
@import './AdvancedSearch/AdvancedSearch.sass';
@import './SearchResults.sass';
@import './About.sass';
@import './Resources.sass';
@import './Feedback.sass';
@import './Changes.sass';
